import React, { useState } from "react";
import styled from "styled-components";
import logo from "../../assets/21logo.jpg";
import dbs from "../../assets/productsPageAssets/21dbs.png";
import hair from "../../assets/productsPageAssets/21hair.png";
import anceSet3 from "../../assets/productsPageAssets/set3x.jpg";
import anceCream from "../../assets/productsPageAssets/acneProduct.png";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";

const Categories = () => {
  const [activeTab1, setActiveTab1] = useState(false);
  const [activeTab2, setActiveTab2] = useState(false);
  const [activeTab3, setActiveTab3] = useState(false);

  const handleToggle = (tab) => {
    if (window.innerWidth <= 768) {
      // Apply click functionality only on mobile devices
      if (tab === 1) setActiveTab1(!activeTab1);
      if (tab === 2) setActiveTab2(!activeTab2);
      if (tab === 3) setActiveTab3(!activeTab3);
    }
  };

  return (
    <Container>
      <div
        className={`category ${activeTab1 ? "active" : ""}`}
        onClick={() => handleToggle(1)}
        onMouseEnter={() => window.innerWidth > 768 && setActiveTab1(true)} // Activate on hover for desktop
        onMouseLeave={() => window.innerWidth > 768 && setActiveTab1(false)} // Deactivate on hover for desktop
      >
        <div className="top">
          <img src={logo} alt="" />
          <h2>21 Ditë pa akne</h2>
          <FaChevronDown className="icon" />
        </div>
        <div className={`bottom bottom1 ${activeTab1 ? "active" : ""}`}>
          <span>
            <Link to={"/produktet/Seti%203x%20(Krem,%20serum,%20larës)"}>
              <img src={anceSet3} alt="" />
            </Link>
            <Link
              to={"/produktet/Seti%203x%20(Krem,%20serum,%20larës)"}
              className="link-styles"
              style={{ color: "gray" }}
            >
              <h3>Seti 3X 21 Ditë pa akne</h3>
            </Link>
          </span>
          <hr />
          <span>
            <Link to={"/produktet/Kremi%20për%20akne"}>
              <img src={anceCream} alt="" />
            </Link>
            <Link
              to={"/produktet/Kremi%20për%20akne"}
              className="link-styles"
              style={{ color: "gray" }}
            >
              <h3>Kremi 21 Ditë pa akne</h3>
            </Link>
          </span>
          <Link to={"/produktet"}>
            <button>Shiko të gjitha</button>
          </Link>
        </div>
      </div>
      <div
        className={`category ${activeTab2 ? "active" : ""}`}
        onClick={() => handleToggle(2)}
        onMouseEnter={() => window.innerWidth > 768 && setActiveTab2(true)}
        onMouseLeave={() => window.innerWidth > 768 && setActiveTab2(false)}
      >
        <div className="top">
          <img src={logo} alt="" />
          <h2>21 Ditë dobësohu</h2>
          <FaChevronDown className="icon" />
        </div>
        <div className={`bottom ${activeTab2 ? "active" : ""}`}>
          <span>
            <Link
              to={"/produktet/Çaji%2021%20Ditë%20dobësohu"}
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              <img src={dbs} alt="" />
            </Link>
            <Link
              to={"/produktet/çaji%2021%20Ditë%20dobësohu"}
              className="link-styles"
              style={{ color: "gray" }}
            >
              <h3>çaji 21 Ditë dobësohu</h3>
            </Link>
          </span>
        </div>
      </div>
      <div
        className={`category ${activeTab3 ? "active" : ""}`}
        onClick={() => handleToggle(3)}
        onMouseEnter={() => window.innerWidth > 768 && setActiveTab3(true)}
        onMouseLeave={() => window.innerWidth > 768 && setActiveTab3(false)}
      >
        <div className="top">
          <img src={logo} alt="" />
          <h2>21 Ditë me flokë</h2>
          <FaChevronDown className="icon" />
        </div>
        <div className={`bottom bottom3 ${activeTab3 ? "active" : ""}`}>
          <span>
            <Link
              to={"/produktet/Seti%2021%20Ditë%20me%20flokë"}
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              <img src={hair} alt="" />
            </Link>
            <Link
              to={"/produktet/Seti%2021%20Ditë%20me%20flokë"}
              className="link-styles"
              style={{ color: "gray" }}
            >
              <h3>Seti 21 Ditë me flokë</h3>
            </Link>
          </span>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 2em 7% 2em 7%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
  flex-wrap: wrap;

  .category {
    width: 31%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: height 0.4s ease;

    @media (max-width: 768px) {
      width: 45%;
    }

    @media (max-width: 480px) {
      width: 100%;
    }

    .top {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #dff5dfc1;
      padding: 20px;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        background: #b0ddb0df;
      }

      img {
        width: 25%;
        border-radius: 50%;

        @media (max-width: 768px) {
          width: 35%;
        }

        @media (max-width: 480px) {
          width: 45%;
        }
      }

      h2 {
        font-weight: 400;
        text-align: center;
      }
    }

    .bottom {
      color: gray;
      width: 100%;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.4s ease, opacity 0.4s ease;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      button {
        padding: 15px 25px;
        border: none;
        color: #4a913c;
        z-index: 1;
        background: #f1f1f1;
        position: relative;
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 19px;
        -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
        box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
        transition: all 250ms;
        cursor: pointer;
        margin-bottom: 1em;

        &:hover {
          color: white;
          background: var(--primaryColor);
        }
      }

      span {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        /* border: 1px solid gray; */
        /* background: #e6e6e6; */
        width: 70%;
        padding: 15px;
        cursor: pointer;
        transition: 0.4s;
        @media (max-width: 480px) {
          padding: 10px;
        }
        &:hover {
          background: #f1f1f1;
        }

        img {
          width: 40%;

          @media (max-width: 768px) {
            width: 50%;
          }

          @media (max-width: 480px) {
            width: 60%;
          }
        }

        h3 {
          font-weight: 300;
          margin: 0;
          text-transform: capitalize;
          text-align: center;
        }
      }
    }

    .bottom3 {
      span {
        img {
          width: 30%;
        }
      }
    }

    .bottom1 {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 10px;
      hr {
        margin: 2em 0;
      }

      span {
        width: 45%;
        text-align: center;

        img {
          height: 100px;
          object-fit: contain;
          width: auto;
        }
      }
    }

    &.active {
      height: auto;
    }

    .bottom.active {
      max-height: 430px;
      opacity: 1;
    }
  }
`;

export default Categories;
