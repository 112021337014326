import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "../components/homeComponents/Banner";
import Stats from "../components/homeComponents/Stats";
import AboutProduct from "../components/homeComponents/AboutProduct";
import ProductOverview from "../components/homeComponents/ProductOverview";
import FAQ from "../components/homeComponents/FAQ";
import Categories from "../components/homeComponents/Categories";
import ContactSection from "../components/homeComponents/ContactSection";
import OurProductsSlider from "../components/homeComponents/OurProductsSlider";
import HomeProducts from "../components/homeComponents/HomeProducts";
import Products from "./Products";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "21 Dite Produkt | Zgjidhja më efektive për akne";
  }, []);
  return (
    <Container>
      <Banner />
      {/* <FacebookChat /> */}
      {/* <Categories /> */}
      <HomeProducts />
      <Stats />
      <AboutProduct />
      <OurProductsSlider />
      {/* <ProductOverview /> */}
      <FAQ />
      <ContactSection />
    </Container>
  );
};
const Container = styled.div`
  padding: 0%;
`;

export default Home;
