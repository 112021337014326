import React from "react";
import styled from "styled-components";
import acneProd from "../../assets/productsPageAssets/acneProduct.png";

const ProductOverview = () => {
  return (
    <Container>
      <div>
        <img src={acneProd} alt="" />
        <span>
          <h2>ACNE FREE DOUBLE EFFECT</h2>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt,
            nisi.
          </p>
        </span>
      </div>
      <div>
        <img src={acneProd} alt="" />
        <span>
          <h2>ACNE FREE DOUBLE EFFECT</h2>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt,
            nisi.
          </p>
        </span>
      </div>
      <div>
        <img src={acneProd} alt="" />
        <span>
          <h2>ACNE FREE DOUBLE EFFECT</h2>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt,
            nisi.
          </p>
        </span>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  color: #292929;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    width: 30%;
    max-width: 500px;
    padding: 20px 30px;
    border-radius: 35px;
    background: #dff5dfc1;
    text-align: center;
    position: relative;
    padding-top: 4em;
    border: 2px solid #c9f8aa;
    box-shadow: 0 7px 10px 1px lightgray;
    img {
      position: absolute;
      bottom: 54%;
      width: 40%;
    }
    span {
      h2 {
        font-weight: 500;
        line-height: 1em;
        font-size: 20px;
      }
      p {
        margin-top: 10px;
        color: gray;
      }
    }
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap;
    gap: 9em 2em;
    div {
      width: 45%;
    }
  }
  @media (max-width: 880px) {
    div {
      width: 95%;
    }
  }
  @media (max-width: 500px) {
    div {
      img {
        width: 57%;
      }
    }
  }
`;

export default ProductOverview;
