import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, EffectCreative } from "swiper/modules";
import image1 from "../../assets/productsPageAssets/swiperImg1.jpg";
import image2 from "../../assets/productsPageAssets/swiperImg2.jpg";
import image3 from "../../assets/productsPageAssets/swiperImg3.jpg";
import { Link } from "react-router-dom";

const OurProductsSlider = () => {
  return (
    <Container>
      <h1 className="bgText">21 Ditë</h1>
      <title>
        <h2>Produktet më të shitura</h2>
      </title>
      <Swiper
        className="swiper leftSwiper"
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay, EffectCreative]}
        slidesPerView={"1"}
        centeredSlides={true}
        speed={300}
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", "20%", 0],
          },
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>21 Ditë pa akne</h4>
            <h1>Kremi për Akne</h1>
            <p>
              Lëkurë të pastër brena tre javësh? Absolutisht! '21 Ditë pa Akne'
              Formula jonë argëtuese dhe efektive largon aknet, duke ju dhënë
              lëkurë të pastër dhe me shkëlqim në vetëm tre javë. Aknet nuk kanë
              asnjë shans!
            </p>
            <div className="btns">
              <Link to={"/produktet/Kremi%20për%20akne"}>
                <button>SHIKO ME SHUMË</button>
              </Link>
            </div>
          </div>
          <img src={image1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>21 Ditë me flokë</h4>
            <h1>Kremi për flokë</h1>
            <p>
              Rigjallëroni rrënjët tuaja me '21 ditë me flokë!' Shikoni flokët
              tuaj të rriten më gjatë dhe më të fortë në vetëm tre javë, duke e
              kthyer çdo ditë në një ditë të mirë të flokëve.
            </p>
            <div className="btns">
              <Link to={"/produktet/Seti%2021%20Ditë%20me%20flokë"}>
                <button>SHIKO ME SHUMË</button>
              </Link>
            </div>
          </div>
          <img src={image2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="left" data-swiper-parallax="-300">
            <h4>21 Ditë dobësohu</h4>
            <h1>çaji 21 Ditë dobësohu</h1>
            <p>
              Hiqni kilogramët e tepërt pa mundim me '21 Ditë Dobësohu!' Çaji
              ynë i shijshëm e bën të lehtë dobësimin. Shijoni një filxhan dhe
              shikoni magjinë të ndodhë!
            </p>
            <div className="btns">
              <Link to={"/produktet/Çaji%2021%20Ditë%20dobësohu"}>
                <button>SHIKO ME SHUMË</button>
              </Link>
            </div>
          </div>
          <img src={image3} alt="" />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};
const Container = styled.div`
  overflow: hidden;
  position: relative;
  padding: 4em 0;
  .bgText {
    position: absolute;
    font-size: 14vw;
    top: 23px;
    width: 100%;
    text-align: center;
    z-index: 0;
    opacity: 0.07;
  }
  title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    text-align: center;
    z-index: 2;
    h2 {
      color: #4d4d4d;
      font-family: "The Seasons", sans-serif;
      font-size: 2em;
      font-weight: 200;
      z-index: 2;
    }
    /* p {
      margin-right: 0;
      color: gray;
      line-height: 2.5em;
      z-index: 2;
    } */
  }
  .swiper {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 6em;
    .slide {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d9eed7ab;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      .left {
        padding: 10% 3% 10% 10%;
        display: flex;
        flex-direction: column;
        h4 {
          color: #707070;
          margin-bottom: 1em;
        }
        h2 {
          font-size: 3vw;
          font-weight: 300;
        }
        h1 {
          font-size: 4vw;
          font-family: "The Seasons", sans-serif;
          font-weight: 200;
          color: #746c65;
          text-transform: capitalize;
        }
        p {
          padding: 1em 0;
          color: gray;
          line-height: 2em;
        }
        .btns {
          display: flex;
          gap: 10px;
          button {
            margin: auto;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            background: white;
            color: #474747;
            border: none;
            padding: 15px 24px;
            cursor: pointer;
            transition: 0.4s;
            margin: 1em;
            &:hover {
              background: var(--primaryColor);
              color: white;
            }
          }
        }
      }
      img {
        width: 47%;
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 5px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #9ae99e; /* Change to your desired active color */
    opacity: 1;
  }
  @media (max-width: 760px) {
    /* .title { */
    .bgText {
      top: 15%;
      font-size: 24vw;
    }
    /* } */
    .swiper {
      .slide {
        .left {
          padding: 10% 3% 10% 4%;
        }
      }
    }
  }
  @media (max-width: 700px) {
    .bgText {
      top: 1px;
    }
    .swiper {
      height: auto;
      .slide {
        gap: 0em;
        flex-direction: column-reverse;
        padding-bottom: 1em;
        .left {
          padding: 7%;
          text-align: center;
          align-items: center;
          h2 {
            font-size: 1.4em;
          }
          h1 {
            font-size: 7vw;
          }
          p {
            height: 100px;
            overflow-y: scroll;
            margin-bottom: 1em;
          }
        }
        img {
          width: 70%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .bgText {
      top: 5%;
      font-size: 24vw;
    }
    .swiper {
      margin-top: 3em;
      height: 80vh;
    }
  }
`;

export default OurProductsSlider;
