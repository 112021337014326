import React from "react";
import styled from "styled-components";

const BlackFriday = () => {
  return <Container>BLACK FRIDAY - ZBRITJE E MADHE</Container>;
};
const Container = styled.div`
  padding: 10px;
  text-align: center;
  background-color: #1f1f1f;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
`;

export default BlackFriday;
