import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import productsData from "../components/productsData";
import { motion, useAnimation, useInView } from "framer-motion";

const ProductPage = () => {
  const { name } = useParams();
  const [quantity, setQuantity] = useState(1);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animation = useAnimation();

  const product = productsData.find((item) => item.name === name);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${name} | 21 Ditë Produkt`;
  }, [name]);

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
  }, [isInView]);

  // Determine the final price for the product page display based on quantity and discount availability
  const isDiscountApplicable =
    (name === "Kremi për akne" ||
      name === "Çaji 21 Ditë dobësohu" ||
      name === "Seti 21 Ditë me flokë") &&
    quantity === 2;

  // Calculate the final price based on product and quantity
  const finalPrice = (() => {
    if (name === "Kremi për akne") {
      return quantity === 2 ? 39.9 : 19.9; // Price logic for Kremi për akne
    }
    if (name === "Kremi për akne") {
      return quantity === 1 ? 39.9 : 19.9; // Price logic for Kremi për akne
    }
    if (name === "Çaji 21 Ditë dobësohu" || name === "Seti 21 Ditë me flokë") {
      return quantity === 2 ? 49.9 : 29.9; // Price logic for Çaji and Seti
    }
    return (product.discountPrice || product.price) * quantity; // Default price logic for other products
  })();

  // Ensure checkoutPrice is a number, default to product.price if not
  const checkoutPrice =
    (name === "Kremi për akne" ||
      name === "Çaji 21 Ditë dobësohu" ||
      name === "Seti 21 Ditë me flokë") &&
    quantity === 2
      ? 39.9 // Special price for 2 items for these products
      : (product.discountPrice || product.price) * quantity;

  return (
    <Container>
      {product && (
        <>
          <motion.img
            ref={ref}
            variants={{
              hidden: { opacity: 0, scale: 0.7 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.2, delay: 0.2, type: "spring" }}
            src={product.image}
            alt={product.name}
          />
          <motion.div
            className="right"
            variants={{
              hidden: { opacity: 0, x: -200 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
          >
            {/* {if (name === "Kremi për akne") ? (
              <p>amiri</p>
            ) : (
              <p>hello</p>
            )} */}
            {/* {name === "Kremi për akne" && (
              <p>
                <b>Ofertë e limituar:</b> 1 krem - 24.90€, 2 krema 39.90€
              </p>
            )} */}
            {name === "Çaji 21 Ditë dobësohu" && (
              <p>
                <b>Ofertë e limituar:</b> 1 Çaj - 29.90€, 2 Çaja 49.90€
              </p>
            )}
            {name === "Seti 21 Ditë me flokë" && (
              <p>
                <b>Ofertë e limituar:</b> 1 set - 29.90€, 2 sete 49.90€
              </p>
            )}
            {/* <p>Oferte e limituar: 1 Set - €24.90, 2 Seta €39.90 </p> */}
            <h4>21 Ditë Produkt</h4>
            <h2>{product.name}</h2>
            <span>
              {product.discountPrice && !isDiscountApplicable ? (
                <>
                  <h2 style={{ textDecoration: "line-through" }}>
                    €{(product.price * quantity).toFixed(2)}
                  </h2>
                  <h1>€{(product.discountPrice * quantity).toFixed(2)}</h1>
                </>
              ) : (
                <h1>€{finalPrice.toFixed(2)}</h1>
              )}
            </span>
            <div>
              <label>Sasia:</label>
              <input
                type="number"
                className="quan"
                min="1"
                max="2"
                value={quantity}
                onChange={(e) =>
                  setQuantity(
                    Math.min(2, Math.max(1, parseInt(e.target.value) || 1))
                  )
                }
              />
            </div>
            <h3>
              {product.unavailable ? (
                <span className="outOfStock">Out of Stock</span>
              ) : (
                <span>In Stock</span>
              )}
            </h3>
            <p>{product.description}</p>
            <div className="btns">
              <Link
                to={`/produktet/checkout/${
                  product.name
                }?quantity=${quantity}&price=${checkoutPrice.toFixed(2)}`}
              >
                <button>VAZHDO TE POROSIA</button>
              </Link>
            </div>
          </motion.div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  color: #5f5f5f;
  transition: 0.5s;
  img {
    width: 49%;
    max-height: 80vh;
    object-fit: contain;
    transition: 0.5s;
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .quan {
      font-size: 1.5em;
      border: 2px solid var(--primaryColor);
      padding: 7px;
      border-radius: 20px;
      margin-left: 10px;
    }
    span {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      h4 {
        font-weight: 300;
      }
      h2 {
        font-weight: 300;
        color: gray;
        font-size: 1.5em;
        text-decoration: line-through;
      }
      h1 {
        color: #278527c1;
      }
    }
    h2 {
      font-size: 2em;
      font-family: "Josefin Sans", sans-serif;
      font-weight: 500;
      text-transform: capitalize;
      color: #278527c1;
    }
    h3 {
      font-weight: 400;
      span {
        color: #585858;
      }
    }
    h1 {
      font-size: 2em;
      font-weight: 400;
      color: gray;
    }
    p {
      color: #a0a0a0;
      line-height: 1.6em;
      font-weight: 300;
      font-size: 18px;
    }
    button {
      margin-top: 1em;
      font-family: "Montserrat", sans-serif;
      color: #85766e;
      font-size: 1.2em;
      padding: 10px 30px;
      background: none;
      border: 1px solid #b9aea7;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: #278527c1;
        color: white;
      }
    }
  }
  @media (max-width: 730px) {
    flex-direction: column;
    .right {
      text-align: center;
      align-items: center;
      width: 100%;
    }
    img {
      width: 100%;
      flex-direction: column;
      align-items: center;
      max-height: 40vh;
    }
  }
  @media (max-width: 550px) {
    .imageDiv {
      .gallery {
        img {
          width: auto;
          height: 70px;
        }
      }
    }
  }
  @media (max-width: 430px) {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`;

export default ProductPage;
