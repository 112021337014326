import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import image from "../../assets/homeAssets/prodImg.png";
import { motion, useAnimation, useInView } from "framer-motion";

const AboutProduct = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.img
        ref={ref}
        variants={{
          hidden: { opacity: 0, x: -100 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        src={image}
        alt=""
      />
      <div>
        <motion.h1
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.4 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
        >
          Përdore sipas udhëzimit dhe shih rezultatin
        </motion.h1>
        <div className="items">
          <motion.span
            variants={{
              hidden: { opacity: 0, y: 100, scale: 0.4 },
              visible: { opacity: 1, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.7, type: "spring" }}
          >
            <h3>Përdorimi</h3>
            <p>
              Mëngjes dhe mbrëmje, cdo ditë (paraprakisht e lani ftyrën me ujë,
              e thani me peshkir dhe aplikoni kremen) Aplikohet në fytyrë deri
              sa nuk mbetet më bardh në lëkurë.
            </p>
          </motion.span>
          <motion.span
            variants={{
              hidden: { opacity: 0, y: 100, scale: 0.4 },
              visible: { opacity: 1, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
          >
            <h3>Benefitet</h3>
            <p>Largon aknet pucrrat shenjat njollat nga fytyra.</p>
          </motion.span>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 0% 3em 7%;
  color: #474747;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  img {
    width: 30%;
    left: 8%;
    position: absolute;
  }
  div {
    padding: 10% 10% 10% 20%;
    border-radius: 70px 0 0 70px;
    text-align: center;
    width: 80%;
    background: #dff5dfc1;
    h1 {
      text-align: start;
      font-size: 4.5vw;
      font-weight: 100;
      font-family: "The Seasons";
    }
    .items {
      width: 100%;
      padding: 0;
      border-radius: 0;
      margin-top: 10%;
      gap: 10px;
      display: flex;
      span {
        h3 {
          font-weight: 500;
        }
        p {
          color: gray;
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    img {
      top: 0;
      width: 40%;
    }
    div {
      margin-top: 30%;
    }
  }
  @media (max-width: 600px) {
    img {
      top: 0;
      width: 40%;
    }
    div {
      margin-top: 30%;
      width: 99%;
      padding: 10%;
      text-align: end;
      h1 {
        text-align: end;
      }
    }
  }
  @media (max-width: 500px) {
    img {
      top: 0;
      width: 60%;
    }
    div {
      margin-top: 42%;
    }
  }
`;

export default AboutProduct;
