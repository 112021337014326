//facebookPixelEvents.js
/* global fbq */
export const pageView = () => {
  if (typeof fbq !== "undefined") {
    fbq("track", "PageView");
    console.log("success");
  } else {
    console.error("Meta Pixel not loaded");
  }
};

export const purchase = () => {
  if (typeof fbq !== "undefined") {
    fbq("track", "TrackOrder");
    console.log("order tracked");
  } else {
    console.error("Meta Pixel not loaded");
  }
};
